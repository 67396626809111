<template>
    <div class="template-page students-projects">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <ThesisList :thesisData="this.thesisData"  />
        </basic-page>
    </div>
</template>

<script>
import { fetchSingleNode } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'
import ThesisList from '../components/ThesisList.vue'
import { fetchNodes } from '../libs/drupalClient'

export default {
  components: { BasicPage, ThesisList },
    name: 'students-projects',
    data: () => {
        return {
            currentPageId: "bde46953-093f-4082-9fdd-f2b8daa4afda",
            pageData: {},
            fulldata: {},
            bannerimg: null,
            thesisData: [],
         }
    },
    mounted() {
        let data;

        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] })
            .then(res => {
                 this.pageData = res[0].attributes
                this.fulldata = res[0]
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })

        // fetch nodes per thesis duplicato da person.vue
        // IN CASO DI MODIFICHE RIPORTARLE
        fetchNodes('thesis', {
                include: ['field_students','field_internal_supervisor','field_funding_stream']
            }).then(res => {

                data = res

            }).then(() => {


                async function fetchStudentPhotos(data) {
                    for (let i = 0; i < data.length; i++) {

                        const el = data[i];
                        
                        try {
                            const response = await fetch(el.field_students.relationships.field_person_photo.links.related.href);
                            const dataPhoto = await response.json();
                        
                        if (dataPhoto.data) {
                            Object.assign(data[i].field_students, dataPhoto.data.attributes.uri);
                        }
                        } catch (error) {
                            console.error('Fetch error:', error);
                        }

                        if (i + 1 === data.length) {
                            this.thesisData = data;
                        }
                    }
                }

                fetchStudentPhotos.call(this, data);

               
            })
    }
}
</script>

<style lang="scss" scoped>
</style>